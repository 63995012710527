/*----------------------------------------
Heading
----------------------------------------*/
.heading {
  &--primary {
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: .02em;
    line-height: 1.35;
    color: $secondary-color;
    @include breakpoint-down() {
      font-size: 3.2rem;
    }
    @include breakpoint-down(xs) {
      font-size: 2.8rem;
    }

    small {
      display: block;
      font-size: 1.2rem;
      color: $gold-color;
      margin-bottom: 1.6rem;
    }
  }

  &--secondary {
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: .01em;
    line-height: 1.2;
    @include breakpoint-down() {
      font-size: 3.6rem;
    }
    @include breakpoint-down(sm) {
      font-size: 3.2rem;
      letter-spacing: .02em;
    }
    @include breakpoint-down(xs) {
      font-size: 2.4rem;
    }

    small {
      display: block;
      font-size: 1.2rem;
      letter-spacing: .02em;
      margin-top: 1.6rem;
    }
  }

  &--tertiary {
    font-size: 3.2rem;
    font-weight: 700;
    letter-spacing: .02em;
    line-height: 1.2;
    @include breakpoint-down() {
      font-size: 2.8rem;
    }
    @include breakpoint-down(sm) {
      font-size: 2.4rem;
    }
    @include breakpoint-down(xs) {
      font-size: 2rem;
      line-height: 1.4;
    }

    small {
      display: block;
      font-size: 1.2rem;
      letter-spacing: .02em;
      margin-bottom: 1.6rem;
    }
  }

  &--quaternary {
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: .02em;
    line-height: 1.2;
    @include breakpoint-down() {
      font-size: 2.2rem;
      line-height: 1.4;
    }
    @include breakpoint-down(sm) {
      font-size: 2rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.8rem;
    }

    small {
      display: block;
      font-size: 1.8rem;
      font-weight: 500;
      margin-top: .8rem;
    }
  }
}