/*----------------------------------------
Top
----------------------------------------*/


/*---------- Hero ----------*/
.top-hero {
  position: relative;
  height: 100%;

  &::before {
    @include absolute($top: 50%, $left: 50%);
    transform: translate(-50%, -50%);
    background: url('../images/common/logo-symbol.svg') center center / contain no-repeat;
    content: '';
    height: 60vh;
    width: 60vh;
    opacity: .5;
    mix-blend-mode: soft-light;
    z-index: 1;
    @include breakpoint-down() {
      height: 75vw;
      width: 75vw;
    }
  }

  &__inner {
    position: relative;
    height: 100vh;
    width: 100%;
  }

  &__slider {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__list {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    @include breakpoint-down() {
      align-items: flex-start;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__item-image {
    @include absolute(0, 0, 0, 0);
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .swiper-slide-active .top-hero__item-image, .swiper-slide-duplicate-active .top-hero__item-image, .swiper-slide-prev .top-hero__item-image {
    animation: zoom 10s linear 0s normal both;
  }

  &__item-body {
    position: relative;
    color: $primary-color-inverse;

    h1 {
      font-size: 4.8rem;
      font-weight: 700;
      letter-spacing: .02em;
      line-height: 1.5;
      @include breakpoint-down() {
        font-size: 4rem;
      }
      @include breakpoint-down(sm) {
        font-size: 3.2rem;
      }
      @include breakpoint-down(xs) {
        font-size: 2.4rem;
      }
    }

    br.is-sp {
      display: none;
      @include breakpoint-down() {
        display: block;
      }
    }

    small {
      display: block;
      margin-bottom: 1.6rem;
    }

    p {
      letter-spacing: .02em;
      max-width: 60rem;
      margin-top: 2.4rem;
    }
  }

  .swiper-slide-active .top-hero__item-body {
    animation: fade 2s 1s normal both;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 8rem;
    font-weight: 800;
    letter-spacing: .01em;
    line-height: 1.2;
    text-shadow: 0 0 1.6rem rgba($primary-color, .5);
    color: $primary-color-inverse;
    height: 100%;
    @include breakpoint-down(sm) {
      font-size: 6.4rem;
    }
    @include breakpoint-down(xs) {
      font-size: 4rem;
    }
  }

  &__media {
    @include absolute($top: 0, $left: 0);
    background-color: $secondary-color;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    @include breakpoint-down() {
      display: none;
    }
  }

  &__media-video {
    @include absolute($top: 50%, $left: 50%);
    transform: translate(-50%, -50%);
    min-height: 100%;
    height: 56.25vw;
    min-width: 177.77vh;
    width: 100%;
  }

  &__scroll {
    @include absolute($bottom: 0, $left: 50%);
    transform: translateX(-50%);
  }

  &__scroll-text {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: .02em;
    color: $primary-color-inverse;
    @include breakpoint-down(sm) {
      font-size: 1.1rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1rem;
    }
  }

  &__scroll-bar {
    position: relative;
    display: block;
    background-color: rgba($primary-color-inverse, .5);
    height: 4.8rem;
    width: .1rem;
    margin-top: .4rem;
    margin-right: auto;
    margin-left: auto;
    @include breakpoint-down(sm) {
      height: 4rem;
    }
    @include breakpoint-down(xs) {
      height: 3.2rem;
    }

    &::after {
      @include absolute();
      background-color: $primary-color-inverse;
      content: '';
      height: 4.8rem;
      width: .1rem;
      animation: scroll 1.6s cubic-bezier(1, 0, 0, 1) infinite;
      @include breakpoint-down(sm) {
        height: 4rem;
      }
      @include breakpoint-down(xs) {
        height: 3.2rem;
      }
    }
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translateX(4rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scroll{
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  49% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}


/*---------- Features ----------*/
.top-features {
  position: relative;
  margin-top: 16rem;
  @include breakpoint-down() {
    margin-top: 12.8rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 8rem;
  }

  &__inner {
    align-items: stretch;
    @include breakpoint-down(sm) {
      width: 90%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__media {
    position: relative;
    width: 40%;
    z-index: 1;
    @include breakpoint-down(sm) {
      display: none;
    }
  }

  &__block {
    position: relative;
    width: 55%;
    margin-left: -50%;
    padding-right: 7%;
    z-index: 0;
    @include breakpoint-down(xl) {
      margin-left: -10%;
    }
    @include breakpoint-down(lg) {
      padding-right: 5%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-left: 0;
      padding-right: 0;
    }
  }

  &__heading {
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: .02em;
    line-height: 1.75;
    color: $secondary-color;
    @include breakpoint-down() {
      font-size: 3.2rem;
    }
    @include breakpoint-down(sm) {
      text-align: center;
    }
    @include breakpoint-down(xs) {
      font-size: 2.8rem;
    }

    small {
      display: block;
      font-size: 1.2rem;
      color: $gold-color;
      margin-bottom: .8rem;
    }

    span {
      position: relative;
      font-size: 5.6rem;
      font-weight: 500;
      color: $tertiary-color;
      @include breakpoint-down() {
        font-size: 4.8rem;
      }
      @include breakpoint-down(xs) {
        font-size: 4rem;
      }

      &:last-child {
        margin-right: .4rem;
      }

      &::before {
        @include absolute($top: -.8rem, $left: 50%);
        transform: translateX(-50%);
        background-color: currentColor;
        border-radius: .5rem;
        content: '';
        height: 1rem;
        width: 1rem;
        @include breakpoint-down() {
          top: -.6rem;
          height: .8rem;
          width: .8rem;
        }
      }
    }
  }

  &__list {
    border-top: .2rem solid $quinary-color;
    margin-top: 4.8rem;
  }

  &__item-link {
    position: relative;
    align-items: center;
    border-bottom: .2rem solid $quinary-color;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-right: 4.4rem;
    transition: border-color .4s;
    @include breakpoint-down(xs) {
      flex-direction: column;
      padding-right: 0;
      padding-bottom: 6.2rem;
    }

    &::before {
      @include absolute($top: 50%, $right: .4rem);
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 14'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E.cls-1%7Bfill:%2335415f;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M13.17,10l-4,4H12l4-4H13.17z M9.17,0l6,6H0v2h20l-8-8H9.17z'/%3E%3C/svg%3E");
      content: '';
      height: 1.4rem;
      width: 2rem;
      transition: right .4s;
      @include breakpoint-down(xs) {
        top: inherit;
        bottom: 2.4rem;
        right: inherit;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    &:hover {
      border-color: $secondary-color;

      &::before {
        right: 0;
      }
    }
  }

  &__item-image {
    display: block;
    width: 11.2rem;
    @include breakpoint-down() {
      width: 8rem;
    }
    @include breakpoint-down(xs) {
      width: 11.2rem;
    }
  }

  &__item-body {
    width: calc(100% - 15.2rem);
    @include breakpoint-down() {
      width: calc(100% - 10.4rem);
    }
    @include breakpoint-down(xs) {
      width: 100%;
      margin-top: 2.4rem;
    }

    h2 {
      color: $tertiary-color;
      @include breakpoint-down(xs) {
        text-align: center;
      }
    }

    p {
      margin-top: 2.4rem;
      @include breakpoint-down(xs) {
        margin-top: 1.6rem;
      }
    }
  }

  &__link {
    margin-top: 4.8rem;
    @include breakpoint-down(sm) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}


/*---------- Voice ----------*/
.top-voice {
  background-color: $senary-color;
  margin-top: 12.8rem;
  @include breakpoint-down() {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 8rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 6.4rem;
  }

  &__inner {
    position: relative;
    padding-top: 9.6rem;
    padding-bottom: 12.8rem;
    @include breakpoint-down(sm) {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__heading {
    text-align: center;
    color: $secondary-color;
  }

  &__list {
    justify-content: inherit;
    column-gap: 2%;
    margin-top: 6.4rem;
    @include breakpoint-down(sm) {
      row-gap: 4.8rem;
      margin-top: 4.8rem;
    }
  }

  &__item {
    max-width: 48rem;
    width: 32%;
    @include breakpoint-down(sm) {
      width: 100%;
      margin: 0 auto;
    }
  }

  &__item-media {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    &:hover svg {
      transform: translate(-50%, -50%) scale(1.1);
    }

    img, iframe {
      @include absolute($top: 50%, $left: 50%);
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    img {
      cursor: pointer;
    }

    svg {
      @include absolute($top: 50%, $left: 50%);
      transform: translate(-50%, -50%);
      fill: $primary-color-inverse;
      height: 8rem;
      width: 8rem;
      pointer-events: none;
      transition: transform .4s;
    }
  }

  &__item-body {
    position: relative;
    margin-top: 2.4rem;
    padding: 0 2rem;
    @include breakpoint-down(sm) {
      margin-top: 1.6rem;
      padding: 0 .8rem;
    }
  }

  &__item-job {
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: .02em;
    color: $tertiary-color;
    @include breakpoint-down(sm) {
      font-size: 1.1rem;
    }
  }

  &__item-title {
    line-height: 1.5;
    width: 100%;
    margin-top: .8rem;
  }

  &__link {
    margin-top: 8rem;
    margin-right: auto;
    margin-left: auto;
    @include breakpoint-down(sm) {
      margin-top: 4.8rem;
    }
  }
}


/*---------- Counseling ----------*/
.top-counseling {
  position: relative;
  margin-top: 16rem;
  @include breakpoint-down() {
    margin-top: 12.8rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 8rem;
  }

  &__inner {
    align-items: stretch;
    padding-top: 8rem;
    @include breakpoint-down(xl) {
      padding-top: 6vw;
    }
    @include breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  &__media {
    position: relative;
    transform: translateY(-8rem);
    width: 50%;
    z-index: 1;
    @include breakpoint-down(xl) {
      transform: translateY(-6vw);
      width: 55%;
    }
    @include breakpoint-down(sm) {
      transform: inherit;
      width: 95%;
    }
  }

  &__block {
    position: relative;
    background-color: $secondary-color;
    width: 65%;
    margin-left: -50%;
    padding-top: 12.8rem;
    padding-right: 7%;
    padding-bottom: 12.8rem;
    padding-left: 22%;
    z-index: 0;
    @include breakpoint-down(xl) {
      width: 55%;
      margin-left: -10%;
      padding-left: 17%;
    }
    @include breakpoint-down(lg) {
      padding-right: 5%;
      padding-left: 15%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: -8rem;
      margin-right: 0;
      margin-left: 0;
      padding-bottom: 6.4rem;
      padding-left: 5%;
    }
  }

  &__block-body {
    max-width: 48rem;
    @include breakpoint-down(xl) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__heading {
    color: $gold-color;
    @include breakpoint-down(sm) {
      text-align: center;
    }
  }

  &__summary {
    font-weight: 400;
    color: $primary-color-inverse;
    margin-top: 3.2rem;
  }

  &__link {
    background-color: $gold-color;
    margin-top: 4.8rem;
    @include breakpoint-down(sm) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}


/*---------- Horizontal ----------*/
.top-horizontal {
  position: relative;
  width: 100%;
  overflow-x: hidden;

  &__text {
    display: inline-block;
    font-size: 12.8rem;
    font-weight: 800;
    letter-spacing: .01em;
    line-height: 1;
    white-space: nowrap;
    color: $senary-color;
    width: 100%;
    padding-top: 12.8rem;
    padding-bottom: 12.8rem;
    @include breakpoint-down(xl) {
      font-size: 9.6rem;
      padding-top: 9.6rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(sm) {
      font-size: 6rem;
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 4.8rem;
      padding-bottom: 4.8rem;
    }
  }
}


/*---------- News ----------*/
.top-news {
  background-color: $senary-color;

  &__inner {
    position: relative;
    padding-top: 9.6rem;
    padding-bottom: 12.8rem;
    @include breakpoint-down(sm) {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__heading {
    text-align: center;
    color: $secondary-color;
  }

  &__link {
    margin-top: 8rem;
    margin-right: auto;
    margin-left: auto;
    @include breakpoint-down(sm) {
      margin-top: 4.8rem;
    }
  }

  .news__item:nth-child(4) {
    display: none;
    @include breakpoint-down(sm) {
      display: block;
    }
  }
}