/*----------------------------------------
News
----------------------------------------*/


/*---------- Hero ----------*/
.news-hero {
  background: url('../images/news/hero.jpg') center center / cover no-repeat;
}


/*---------- Content ----------*/
.news {
  &__inner {
    padding-top: 9.6rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 4.8rem;
      padding-bottom: 6.4rem;
    }
  }

  &__category {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include breakpoint-down(xs) {
      justify-content: space-between;
    }
  }

  &__category-link {
    position: relative;
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 1.4;
    text-align: center;
    border: 1px solid rgba($secondary-color, .2);
    color: $secondary-color;
    width: 16rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    overflow: hidden;
    transition: border-color .4s, color .4s;
    @include breakpoint-down() {
      font-size: 1.6rem;
    }
    @include breakpoint-down(sm) {
      font-size: 1.4rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.2rem;
      width: 32%;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    &::before {
      @include absolute($top:0, $right: -8rem, $bottom: 0, $left: 0);
      transform: translateX(-100%);
      border-right: 8rem solid transparent;
      border-bottom: 8rem solid $tertiary-color;
      content: '';
      transition: transform .4s;
      z-index: 0;
    }

    &:hover {
      border-color: transparent;
      color: $primary-color-inverse;

      &::before {
        transform: translateX(0);
      }
    }

    &:nth-child(n + 2) {
      margin-left: .8rem;
      @include breakpoint-down(xs) {
        margin-left: 0;
      }
    }

    &.is-active {
      background-color: $secondary-color;
      border-color: transparent;
      color: $primary-color-inverse;
      pointer-events: none;
    }
  }

  &__category-link-text {
    position: relative;
    z-index: 1;
  }

  &__heading {
    font-weight: 500;
    text-align: center;
    margin-top: 6.4rem;
    @include breakpoint-down(sm) {
      margin-top: 4.8rem;
    }
  }
}