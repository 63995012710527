/*----------------------------------------
Variables
----------------------------------------*/


/*---------- Theme Color ----------*/
$primary-color: #222222;
$primary-color-inverse: #fff;
$secondary-color: #35415f;
$tertiary-color: #426da9;
$quaternary-color: #94b5dc;
$quinary-color: #dce5ef;
$senary-color: #f0f4f8;
$accent-color: #bc9d3a;

$gold-color: #bc9d3a;
$red-color: #bd3a46;
$gray-color: #afafaf;


/*---------- Breakpoint ----------*/
$breakpoint-min: ( // min-width
  'xs': 'screen and (min-width: 36em)',
  'sm': 'screen and (min-width: 48em)',
  'md': 'screen and (min-width: 60em)',
  'lg': 'screen and (min-width: 80em)',
  'xl': 'screen and (min-width: 90em)',
) !default;

$breakpoint-max: ( // max-width
  'xs': 'screen and (max-width: 36em)',
  'sm': 'screen and (max-width: 48em)',
  'md': 'screen and (max-width: 60em)',
  'lg': 'screen and (max-width: 80em)',
  'xl': 'screen and (max-width: 90em)',
) !default;