/*----------------------------------------
About
----------------------------------------*/


/*---------- Hero ----------*/
.voice-hero {
  background: url('../images/voice/hero.jpg') center center / cover no-repeat;
}


/*---------- Statement ----------*/
.voice-intro {
  margin-top: 12.8rem;
  @include breakpoint-down() {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 6.4rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 4.8rem;
  }

  &__heading {
    color: $secondary-color;
    width: 75%;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__subheading {
    color: $accent-color;
    width: 75%;
    margin-top: 4.8rem;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 3.2rem;
    }
  }

  &__content {
    font-size: 1.8rem;
    width: 75%;
    margin-top: 3.2rem;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      font-size: 1.6rem;
      width: 100%;
      margin-top: 2.4rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.4rem;
    }
  }
}


/*---------- Movies ----------*/
.voice-movies {
  &__inner {
    max-width: 96rem;
    padding-top: 16rem;
    padding-bottom: 19.2rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 9.6rem;
    @include breakpoint-down(sm) {
      margin-top: 6.4rem;
    }
    @include breakpoint-down(xs) {
      margin-top: 4.8rem;
    }

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(2) {
      flex-direction: row-reverse;
    }

    & > div:first-child {
      width: 52%;
      @include breakpoint-down(sm) {
        width: 100%;
      }
    }

    & > div:last-child {
      width: 40%;
      @include breakpoint-down(sm) {
        text-align: center;
        width: 100%;
        margin-top: 2.4rem;
      }
    }
  }

  &__item-media {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    &:hover svg {
      transform: translate(-50%, -50%) scale(1.1);
    }

    img, iframe {
      @include absolute($top: 50%, $left: 50%);
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    img {
      cursor: pointer;
    }

    svg {
      @include absolute($top: 50%, $left: 50%);
      transform: translate(-50%, -50%);
      fill: $primary-color-inverse;
      height: 8rem;
      width: 8rem;
      pointer-events: none;
      transition: transform .4s;
    }
  }

  &__item-num {
    display: block;
    color: $secondary-color;
  }

  &__item-heading {
    font-size: 1.8rem;
    color: $secondary-color;
    margin-top: 3.2rem;
    @include breakpoint-down(sm) {
      font-size: 1.6rem;
      margin-top: 2.4rem;
    }
  }

  &__item-job {
    margin-top: 1.6rem;
  }
}