/*!------------------------------------------------------------------
Base
-------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;500;700;800&display=swap');

*, :before, :after {
  box-sizing: inherit;
  outline: none;
  -webkit-touch-callout: none;
}

html {
  box-sizing: border-box;
}

html, body {
  font-family: 'M PLUS 1p', sans-serif;
  font-weight: 500;
  font-size: 62.5%;
  font-style: normal;
  letter-spacing: .04em;
  line-height: 1.4;
  background-color: $primary-color-inverse;
  color: $primary-color;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

p {
  font-size: 1.4rem;
  line-height: 1.75;
  @include breakpoint-down(sm) {
    font-size: 1.3rem;
  }
}

a {
  text-decoration: none;
  color: $primary-color;
}

figure, ol, ul {
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style-type: none;
}

img, video {
  width: 100%;
  vertical-align: bottom;
}

img {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
}

button, input, select, textarea {
  font-family: 'M PLUS 1p', sans-serif;
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  outline: 0;
  margin: 0;
  padding: 0;
}

input, select, textarea {
  font-size: 1.4rem;
  line-height: 1.75;
  -webkit-user-select : auto;
  @include breakpoint-down(sm) {
    font-size: 1.3rem;
  }
}