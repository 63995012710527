/*----------------------------------------
News List
----------------------------------------*/
.news {
  &__list {
    justify-content: inherit;
    gap: 9.6rem 2%;
    margin-top: 6.4rem;
    @include breakpoint-down(sm) {
      gap: 4rem 4%;
      margin-top: 4.8rem;
    }
  }

  &__item {
    width: 32%;
    @include breakpoint-down(sm) {
      width: 48%;
    }
  }

  &__item-link {
    &:hover .news__item-media-image {
      transform: rotateY(-11deg) rotateX(8deg) scale(0.995);
    }
  }

  &__item-media {
    position: relative;
    perspective: 1200px;

    &::before {
      @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
      background-color: $secondary-color;
      content: '';
    }
  }

  &__item-media-image {
    position: relative;
    transform-origin: 100% 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: 66.666666%;
    transition: transform .4s;
  }

  &__item-media-category {
    @include absolute($top: .8rem, $left: .8rem);
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 1.2;
    background-color: $secondary-color;
    color: $primary-color-inverse;
    padding: .5rem .8rem .4rem;
    @include breakpoint-down(sm) {
      top: 0;
      left: 0;
      font-size: 1.1rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1rem;
    }
  }

  &__item-body {
    position: relative;
    margin-top: 2.4rem;
    padding-right: 6rem;
    padding-left: 2rem;
    @include breakpoint-down(sm) {
      margin-top: 1.6rem;
      padding-right: .8rem;
      padding-left: .8rem;
    }

    &::before {
      @include absolute($top: 50%, $right: 2rem);
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 14'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E.cls-1%7Bfill:%2335415f;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M13.17,10l-4,4H12l4-4H13.17z M9.17,0l6,6H0v2h20l-8-8H9.17z'/%3E%3C/svg%3E");
      content: '';
      height: 1.4rem;
      width: 2rem;
      @include breakpoint-down(sm) {
        content: none;
      }
    }
  }

  &__item-date {
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: .02em;
    color: $secondary-color;
    @include breakpoint-down(sm) {
      font-size: 1.1rem;
    }
  }

  &__item-title {
    line-height: 1.5;
    width: 100%;
    margin-top: .8rem;
  }
}