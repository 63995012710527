/*----------------------------------------
News Article
----------------------------------------*/


/*---------- Hero ----------*/
.article-hero {
  background: url('../images/news/article_hero.jpg') center center / cover no-repeat;
}


/*---------- Content ----------*/
.news-article {
  &__inner {
    padding-top: 9.6rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 4.8rem;
      padding-bottom: 6.4rem;
    }
  }

  &__header {
    width: 75%;
    @include breakpoint-down(lg) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__title {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.4;
    color: $secondary-color;
    @include breakpoint-down() {
      font-size: 3.2rem;
    }
    @include breakpoint-down(sm) {
      font-size: 2.8rem;
    }
    @include breakpoint-down(xs) {
      font-size: 2.4rem;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2.4rem;
    @include breakpoint-down(xs) {
      margin-top: 1.6rem;
    }
  }

  &__info-category {
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 1.2;
    background-color: $secondary-color;
    color: $primary-color-inverse;
    padding: .5rem .8rem .4rem;
    @include breakpoint-down(sm) {
      font-size: 1.1rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1rem;
    }
  }

  &__info-date {
    font-size: 1.4rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 2;
    color: $secondary-color;
    margin-left: 1.6rem;
    @include breakpoint-down() {
      font-size: 1.3rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.2rem;
      margin-left: .8rem;
    }
  }

  &__media {
    width: 75%;
    margin-top: 6.4rem;
    padding-bottom: .8rem;
    @include breakpoint-down(lg) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 4.8rem;
    }
  }

  &__content {
    width: 75%;
    margin-top: 6.4rem;
    @include breakpoint-down(lg) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 4.8rem;
    }

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: $secondary-color;
      margin-top: 4rem;
      @include breakpoint-down(sm) {
        font-size: 1.8rem;
        margin-top: 3.2rem;
      }
    }

    h3 {
      font-size: 1.6rem;
      font-weight: 700;
      color: $secondary-color;
      margin-top: 4rem;
      @include breakpoint-down(sm) {
        font-size: 1.5rem;
        margin-top: 3.2rem;
      }
    }

    p {
      margin-top: 2.4rem;
      @include breakpoint-down(sm) {
        margin-top: 1.6rem;
      }
    }

    a {
      text-decoration: underline;
      transition: color .4s;

      &:hover {
        color: $tertiary-color;
      }
    }

    figure {
      width: 65%;
      margin-top: 6.4rem;
      padding-bottom: .8rem;
      @include breakpoint-down(sm) {
        width: 100%;
        margin-top: 4.8rem;
      }
    }
  }

  &__nav {
    border-top: 1px solid $quaternary-color;
    margin-top: 9.6rem;
    padding-top: 2.4rem;
    @include breakpoint-down() {
      margin-top: 8rem;
    }
    @include breakpoint-down(sm) {
      padding-top: 1.6rem;
    }
    @include breakpoint-down(xs) {
      margin-top: 4.8rem;
    }
  }

  &__nav-prev, &__nav-next {
    font-size: 2.4rem;
    font-weight: 800;
    letter-spacing: .02em;
    color: $secondary-color;
    transition: color .4s;
    @include breakpoint-down(md) {
      font-size: 2.2rem;
    }
    @include breakpoint-down(sm) {
      font-size: 2rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.8rem;

    }

    &:hover {
      color: $tertiary-color;
    }
  }

  &__nav-prev {
    margin-right: auto;

    &::before {
      content: '❮';
      margin-right: .8rem;
    }
  }

  &__nav-next {
    margin-left: auto;

    &::after {
      content: '❯';
      margin-left: .8rem;
    }
  }
}