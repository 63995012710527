/*----------------------------------------
Works
----------------------------------------*/


/*---------- Hero ----------*/
.careers-hero {
  background: url('../images/careers/hero.jpg') center center / cover no-repeat;
}


/*---------- Introduction ----------*/
.careers-intro {
  margin-top: 12.8rem;
  @include breakpoint-down() {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 6.4rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 4.8rem;
  }

  &__heading {
    color: $accent-color;
    width: 75%;
    @include breakpoint-down(lg) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__content {
    font-size: 1.8rem;
    width: 75%;
    margin-top: 3.2rem;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      font-size: 1.6rem;
      width: 100%;
      margin-top: 2.4rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.4rem;
    }
  }
}


/*---------- About ----------*/
.careers-about {
  position: relative;
  margin-top: 12.8rem;
  @include breakpoint-down() {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 8rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 6.4rem;
  }

  &__inner {
    align-items: stretch;
    padding-top: 8rem;
    @include breakpoint-down(xl) {
      padding-top: 6vw;
    }
    @include breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  &__media {
    position: relative;
    transform: translateY(-8rem);
    width: 50%;
    z-index: 1;
    @include breakpoint-down(xl) {
      transform: translateY(-6vw);
      width: 55%;
    }
    @include breakpoint-down(sm) {
      transform: inherit;
      width: 95%;
    }
  }

  &__block {
    position: relative;
    background-color: $secondary-color;
    width: 65%;
    margin-left: -50%;
    padding-top: 12.8rem;
    padding-right: 7%;
    padding-bottom: 6.4rem;
    padding-left: 22%;
    z-index: 0;
    @include breakpoint-down(xl) {
      width: 55%;
      margin-left: -10%;
      padding-left: 17%;
    }
    @include breakpoint-down(lg) {
      padding-right: 5%;
      padding-left: 15%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: -8rem;
      margin-right: 0;
      margin-left: 0;
      padding-left: 5%;
    }
  }

  &__block-body {
    max-width: 38rem;
    @include breakpoint-down(xl) {
      margin-right: auto;
      margin-left: auto;
    }
    @include breakpoint-down(sm) {
      max-width: 48rem;
    }
  }

  &__heading {
    color: $accent-color;
  }

  &__summary {
    font-weight: 400;
    color: $primary-color-inverse;
    margin-top: 3.2rem;
  }

  &__link {
    background-color: $accent-color;
    color: $primary-color-inverse;
    margin-top: 3.2rem;
  }
}


/*---------- Company Policy ----------*/
.careers-policy {
  background-color: $quinary-color;
  margin-top: 12.8rem;
  @include breakpoint-down() {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 6.4rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 4.8rem;
  }

  &__inner {
    position: relative;
    padding-top: 9.6rem;
    padding-bottom: 12.8rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__heading {
    text-align: center;
    color: $secondary-color;
  }

  &__list {
    margin-top: 6.4rem;
    @include breakpoint-down(sm) {
      margin-top: 4.8rem;
    }
  }

  &__item {
    width: 32%;
    @include breakpoint-down() {
      width: 48%;
    }
    @include breakpoint-down(xs) {
      max-width: 38rem;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }

    &:nth-child(2) {
      @include breakpoint-down(xs) {
        margin-top: 4rem;
      }
    }

    &:nth-child(3) {
      @include breakpoint-down() {
        margin-top: 4.8rem;
        margin-right: auto;
        margin-left: auto;
      }
      @include breakpoint-down(sm) {
        margin-top: 4rem;
      }
    }
  }

  &__item-body {
    padding-top: 3.2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    @include breakpoint-down(sm) {
      padding-top: 2.4rem;
      padding-right: .8rem;
      padding-left: .8rem;
    }
  }

  &__item-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    text-align: center;
    color: $secondary-color;

    &::before, &::after {
      background-color: currentColor;
      content: '';
      height: .2rem;
      width: 1.6rem;
    }

    &::before {
      margin-right: 1.6rem;
    }

    &::after {
      margin-left: 1.6rem;
    }
  }

  &__item-content {
    margin-top: 2.4rem;
    @include breakpoint-down(sm) {
      margin-top: 1.6rem;
    }
  }
}


/*---------- Now Hiring ----------*/
.careers-hiring {
  &__inner {
    padding-top: 12.8rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__body {
    width: 48%;
    margin-top: 4rem;
    padding-right: 8%;
    padding-left: 8%;
    @include breakpoint-down() {
      padding-right: 0;
      padding-left: 0;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__heading {
    color: $secondary-color;
  }

  &__content {
    margin-top: 3.2rem;
  }

  &__recruit {
    margin-top: 4.8rem;
  }

  &__recruit-heading {
    font-size: 1.8rem;
    font-weight: 700;
    color: $secondary-color;
    @include breakpoint-down() {
      font-size: 1.6rem;
    }
  }

  &__recruit-list {
    margin-top: 2.4rem;
  }

  &__recruit-item {
    &:nth-child(2) {
      margin-top: 1.6rem;
    }
  }

  &__recruit-item-link {
    position: relative;
    align-items: center;
    font-size: 1.8rem;
    text-align: center;
    border: 1px solid rgba($secondary-color, .2);
    color: $secondary-color;
    padding-top: 1.6rem;
    padding-right: 2rem;
    padding-bottom: 1.6rem;
    padding-left: 2rem;
    overflow: hidden;
    transition: border-color .4s, color .4s;
    @include breakpoint-down() {
      font-size: 1.6rem;
    }
    @include breakpoint-down(sm) {
      font-size: 1.4rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 1.2rem;
      padding-right: 1.6rem;
      padding-bottom: 1.2rem;
      padding-left: 1.6rem;
    }

    &::before {
      @include absolute($top:0, $right: -8rem, $bottom: 0, $left: 0);
      transform: translateX(-100%);
      border-right: 8rem solid transparent;
      border-bottom: 8rem solid $tertiary-color;
      content: '';
      transition: transform .4s;
      z-index: 0;
    }

    &:hover {
      border-color: transparent;
      color: $primary-color-inverse;

      &::before {
        transform: translateX(0);
      }
    }

    svg {
      position: relative;
      fill: currentColor;
      height: 1.6rem;
      width: 1.6rem;
      z-index: 1;
      @include breakpoint-down(sm) {
        height: 1.4rem;
        width: 1.4rem;
      }
    }
  }

  &__recruit-item-text {
    position: relative;
    z-index: 1;
  }

  &__media {
    width: 48%;
    @include breakpoint-down() {
      margin-right: -5vw;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }
}