/*----------------------------------------
Features
----------------------------------------*/


/*---------- Hero ----------*/
.counseling-hero {
  background: url('../images/counseling/hero.jpg') center center / cover no-repeat;
}


/*---------- Intro ----------*/
.counseling-intro {
  overflow: hidden;

  &__inner {
    padding-top: 12.8rem;
    padding-bottom: 6.4rem;
    @include breakpoint-down() {
      padding-top: 9.6rem;
      padding-bottom: 3.2rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 1.6rem;
    }
  }

  &__block {
    width: 50%;
    padding-left: 8%;
    @include breakpoint-down(lg) {
      width: 55%;
    }
    @include breakpoint-down() {
      padding-left: 0;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 0;
    }

    p {
      margin-top: 6.4rem;
      @include breakpoint-down(sm) {
        margin-top: 4.8rem;
      }
    }

    p + p {
      margin-top: 2.4rem;
      @include breakpoint-down(sm) {
        margin-top: 1.6rem;
      }
    }
  }

  &__media {
    position: relative;
    width: 40%;
    z-index: 1;
    @include breakpoint-down(lg) {
      margin-right: -5vw;
    }
    @include breakpoint-down(sm) {
      width: 95vw;
      margin-top: 4.8rem;
      margin-right: 0;
      margin-left: -5vw;
      padding-top: 66.666666%;
    }

    img {
      @include breakpoint-down(sm) {
        @include absolute($top: 50%, $left: 50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}


/*---------- Contents ----------*/
.counseling-contents {
  &__inner {
    position: relative;
    padding-top: 9.6rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-bottom: 12.8rem;
    }
    @include breakpoint-down(sm) {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__heading {
    text-align: center;
    color: $secondary-color;
  }

  &__list {
    justify-content: center;
    column-gap: min(8%, 6.4rem);
    margin-top: 8rem;
    @include breakpoint-down(sm) {
      row-gap: 4.8rem;
      margin-top: 4.8rem;
    }
  }

  &__item {
    max-width: 32rem;
    width: 28%;
    @include breakpoint-down(sm) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 auto;
    }

    img {
      display: block;
      width: min(80%, 24rem);
      margin: 0 auto;
      @include breakpoint-down(sm) {
        width: 16rem;
      }
    }
  }

  &__item-body {
    margin-top: 4.8rem;
    @include breakpoint-down(sm) {
      margin-top: 2.4rem;
    }

    h3 {
      color: $tertiary-color;
      text-align: center;
    }

    p {
      margin: 2.4rem auto 0;
      @include breakpoint-down(sm) {
        margin: 1.6rem auto 0;
      }
    }
  }
}


/*---------- Voice ----------*/
.counseling-voice {
  background-color: $senary-color;

  &__inner {
    position: relative;
    padding-top: 9.6rem;
    padding-bottom: 12.8rem;
    @include breakpoint-down(sm) {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__heading {
    text-align: center;
    color: $secondary-color;
  }

  &__list {
    justify-content: inherit;
    column-gap: 2%;
    margin-top: 6.4rem;
    @include breakpoint-down(sm) {
      row-gap: 4.8rem;
      margin-top: 4.8rem;
    }
  }

  &__item {
    max-width: 48rem;
    width: 32%;
    @include breakpoint-down(sm) {
      width: 100%;
      margin: 0 auto;
    }
  }

  &__item-media {
    position: relative;
    max-width: 32rem;
    margin: 0 auto;
    @include breakpoint-down(sm) {
      width: 60%;
    }
  }

  &__item-media-image {
    position: relative;
    transform-origin: 100% 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: 56.25%;
  }

  &__item-body {
    margin-top: 2.4rem;
    padding: 0 2rem;
    @include breakpoint-down(sm) {
      margin-top: 1.6rem;
      padding: 0 .8rem;
    }
  }

  &__item-job {
    font-size: 1.8rem;
    color: $tertiary-color;
    @include breakpoint-down(sm) {
      font-size: 1.6rem;
      text-align: center;
    }
  }

  &__item-title {
    line-height: 1.5;
    width: 100%;
    margin-top: .8rem;
  }
}


/*---------- Present ----------*/
.counseling-present {
  overflow: hidden;

  &__inner {
    align-items: center;
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__block {
    width: 36%;
    margin-top: -8vw;
    padding-left: 8%;
    @include breakpoint-down() {
      padding-left: 0;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 0;
    }

    h2 {
      color: $secondary-color;
      @include breakpoint-down(sm) {
        text-align: center;
      }
    }

    p {
      margin-top: 3.2rem;
    }
  }

  &__media {
    width: 64%;
    margin-right: -4%;
    @include breakpoint-down(sm) {
      width: 100%;
      margin-right: 0;
    }
  }
}


/*---------- Form ----------*/
.counseling-form {
  position: relative;
  background-color: $secondary-color;

  &::before {
    @include absolute($bottom: 0, $left: 0);
    background: url('../images/counseling/logo-wordmark.svg') center bottom / contain no-repeat;
    content: '';
    height: 13.75vw;
    width: 100%;
    opacity: .03;
  }

  &__inner {
    position: relative;
    max-width: 96rem;
    width: 90%;
    margin: 0 auto;
    padding-top: 9.6rem;
    padding-bottom: 16rem;
    @include breakpoint-down(sm) {
      padding-top: 8rem;
      padding-bottom: 12.8rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 9.6rem;
    }
  }

  &__heading {
    text-align: center;
    color: $gold-color;

    br.is-sp {
      display: none;
      @include breakpoint-down(sm) {
        display: block;
      }
    }
  }

  .hbspt-form {
    margin-top: 6.4rem;
    @include breakpoint-down(sm) {
      margin-top: 4.8rem;
    }
  }
}


/*---------- Faq ----------*/
.counseling-faq {
  &__inner {
    position: relative;
    max-width: 96rem;
    width: 90%;
    margin: 0 auto;
    padding-top: 9.6rem;
    padding-bottom: 12.8rem;
    @include breakpoint-down(sm) {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__heading {
    text-align: center;
    color: $secondary-color;
  }

  &__summary {
    font-size: 1.6rem;
    width: fit-content;
    margin: 3.2rem auto 0;
  }

  &__list {
    margin-top: 6.4rem;
    @include breakpoint-down(sm) {
      margin-top: 4.8rem;
    }
  }

  &__item-question {
    position: relative;
    border-top: .2rem solid $quinary-color;
    padding: 4rem 1.6rem 4rem 6.4rem;
    @include breakpoint-down(sm) {
      padding: 2.8rem 1.6rem 2.8rem 5.4rem;
    }

    &::before {
      @include absolute($top: 4rem, $left: 1.6rem);
      font-size: 2.4rem;
      font-weight: 700;
      letter-spacing: .02em;
      color: currentColor;
      content: 'Q.';
      @include breakpoint-down() {
        font-size: 2.2rem;
      }
      @include breakpoint-down(sm) {
        top: 2.8rem;
        font-size: 2rem;
      }
      @include breakpoint-down(xs) {
        font-size: 1.8rem;
      }
    }
  }

  &__item-answer {
    position: relative;
    background-color: $senary-color;
    color: $tertiary-color;
    padding: 1.6rem 1.6rem 1.6rem 6.4rem;
    @include breakpoint-down(sm) {
      padding: 1.6rem 1.6rem 1.6rem 5.4rem;
    }

    &::before {
      @include absolute($top: .8rem, $left: 1.6rem);
      font-size: 2.4rem;
      font-weight: 700;
      letter-spacing: .02em;
      color: currentColor;
      content: 'A.';
      @include breakpoint-down() {
        font-size: 2.2rem;
      }
      @include breakpoint-down(sm) {
        top: 1.2rem;
        font-size: 2rem;
      }
      @include breakpoint-down(xs) {
        font-size: 1.8rem;
      }
    }
  }
}