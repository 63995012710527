/*----------------------------------------
About
----------------------------------------*/


/*---------- Hero ----------*/
.about-hero {
  background: url('../images/about/hero.jpg') center center / cover no-repeat;
}


/*---------- Statement ----------*/
.about-statement {
  margin-top: 12.8rem;
  @include breakpoint-down() {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 6.4rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 4.8rem;
  }

  &__heading {
    color: $secondary-color;
    width: 75%;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__subheading {
    color: $accent-color;
    width: 75%;
    margin-top: 4.8rem;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 3.2rem;
    }
  }

  &__content {
    font-size: 1.8rem;
    width: 75%;
    margin-top: 3.2rem;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      font-size: 1.6rem;
      width: 100%;
      margin-top: 2.4rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.4rem;
    }
  }
}


/*---------- Vision ----------*/
.about-vision {
  &__inner {
    align-items: center;
    padding-top: 12.8rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__body {
    width: 48%;
    padding-right: 8%;
    padding-left: 8%;
    @include breakpoint-down() {
      padding-right: 0;
      padding-left: 0;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 4rem;
    }
  }

  &__heading {
    color: $secondary-color;
  }

  &__subheading {
    color: $accent-color;
    margin-top: 3.2rem;
  }

  &__media {
    width: 48%;
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }
}


/*---------- Values ----------*/
.about-values {
  background-color: $secondary-color;

  &__inner {
    padding-top: 12.8rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }
  }

  &__body {
    position: relative;
    overflow: hidden;
  }

  &__header-heading, &__header-fraction {
    color: $primary-color-inverse;
  }

  &__list {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 4.8rem;
    z-index: 1;
    @include breakpoint-down() {
      align-items: flex-start;
    }
  }

  &__item {
    position: relative;
    flex-shrink: 0;
    align-items: center;
    width: 100%;
    padding-right: 4.8rem;
    padding-left: 4.8rem;
    @include breakpoint-down(xs) {
      padding-right: 2.8rem;
      padding-left: 2.8rem;
    }
  }

  &__item-title {
    text-align: center;
    width: 40%;
    @include breakpoint-down() {
      text-align: left;
      width: 100%;
    }
  }

  &__item-title-text {
    display: inline-block;
    font-size: 9.6rem;
    font-weight: 800;
    letter-spacing: .01em;
    line-height: 1;
    text-align: left;
    word-break: break-all;
    color: $accent-color;
    @include breakpoint-down() {
      font-size: 6.4rem;
      line-height: 1.2;
    }
    @include breakpoint-down(sm) {
      font-size: 4.8rem;
    }
    @include breakpoint-down(xs) {
      font-size: 3.2rem;
      letter-spacing: .02em;
    }

    br {
      @include breakpoint-down() {
        display: none;
      }
    }
  }

  &__item-body {
    width: 54%;
    @include breakpoint-down() {
      width: 100%;
      margin-top: 3.2rem;
    }
  }

  &__item-heading {
    color: $primary-color-inverse;
  }

  &__item-content {
    color: $primary-color-inverse;
    margin-top: 3.2rem;
    @include breakpoint-down() {
      margin-top: 2.4rem;
    }
  }

  &__prev, &__next {
    @include absolute($top: 50%);
    transform: translateY(calc(-50% + 4.8rem));
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    color: $primary-color-inverse;
    height: 4.8rem;
    width: 4.8rem;
    z-index: 2;
    @include breakpoint-down(sm) {
      font-size: 2.2rem;
    }
    @include breakpoint-down(xs) {
      transform: translateY(calc(-50% + 4rem));
      font-size: 2rem;
      height: 3.2rem;
      width: 3.2rem;
    }
  }

  &__prev {
    left: -1.6rem;
    @include breakpoint-down(xs) {
      left: -1rem;
    }
  }

  &__next {
    right: -1.6rem;
    @include breakpoint-down(xs) {
      right: -1rem;
    }
  }
}


/*---------- CEO’s Message ----------*/
.about-ceo {
  &__inner {
    padding-top: 12.8rem;
    @include breakpoint-down() {
      padding-top: 8rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 6.4rem;
    }
  }

  &__heading {
    color: $secondary-color;
    width: 75%;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__content {
    width: 75%;
    margin-top: 6.4rem;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 3.2rem;
    }
  }

  &__sign {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 4.8rem;
    @include breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-end;
      margin-top: 3.2rem;
    }
  }

  &__sign-text {
    line-height: 1.7;
    color: $secondary-color;

    br {
      @include breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__sign-image {
    width: 16rem;
    margin-left: 3.2rem;
    @include breakpoint-down(sm) {
      width: 12.8rem;
      margin-top: .8rem;
      margin-left: 0;
    }
    @include breakpoint-down(xs) {
      width: 11.2rem;
    }
  }
}

/*---------- Company Profile ----------*/
.about-outline {
  &__inner {
    padding-top: 12.8rem;
    padding-bottom: 4.3rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 0;
    }
  }

  &__heading {
    color: $secondary-color;
  }

  &__list {
    width: 75%;
    margin-top: 6.4rem;
    padding-left: 8%;
    @include breakpoint-down(xl) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 4rem;
      padding-left: 0;
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $quaternary-color;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    @include breakpoint-down(sm) {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }
  }

  &__item-label, &__item-data {
    font-size: 1.4rem;
    line-height: 2;
    @include breakpoint-down(sm) {
      font-size: 1.3rem;
    }
  }

  &__item-label {
    color: $secondary-color;
    width: 16rem;
    @include breakpoint-down(sm) {
      width: 11.2rem;
    }
  }

  &__item-data {
    width: calc(100% - 16rem);
    @include breakpoint-down(sm) {
      width: calc(100% - 11.2rem);
    }
  }

  &__item-tel {
    &:hover {
      text-decoration: underline;
    }
  }
}


/*---------- Access ----------*/
.about-access {
  padding-top: 9.3rem;
  @include breakpoint-down() {
    padding-top: 7.8rem;
  }
  @include breakpoint-down(xs) {
    padding-top: 6.1rem;
  }

  &__map {
    height: 32vw;
    width: 100%;
    @include breakpoint-down(xl) {
      height: 44.8rem;
    }
    @include breakpoint-down(xs) {
      height: 24rem;
    }
  }

  &__inner {
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2.4rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    @include breakpoint-down(xs) {
      padding-bottom: 6.4rem;
    }
  }

  &__address {
    display: flex;
    align-items: center;
    width: 75%;
    @include breakpoint-down(xl) {
      width: 70%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__address-image {
    height: 3.2rem;
    width: 3.2rem;
    @include breakpoint-down(sm) {
      height: 2.4rem;
      width: 2.4rem;
    }
  }

  &__address-text {
    font-size: 1.8rem;
    color: $secondary-color;
    margin-left: 1.6rem;
    @include breakpoint-down(sm) {
      font-size: 1.6rem;
      margin-left: .8rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.4rem;
    }
  }

  &__link {
    @include breakpoint-down(sm) {
      margin-top: 3.2rem;
    }
  }
}