/*----------------------------------------
Common
----------------------------------------*/
body.is-opened {
  @include breakpoint-down(md) {
    overflow-y: hidden;
  }
}

.wrapper {
  width: 100%;

  &__overlay {
    @include fixed($top: 0, $right: 0, $bottom: 0, $left: 0);
    display: none;
    background-color: $primary-color;
    height: 100%;
    width: 100%;
    opacity: 0;
    @include breakpoint-down(xs) {
      display: block;
    }
  }
}

.main {
  position: relative;
}

.container {
  max-width: 144rem;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


/*----------------------------------------
Javascript
----------------------------------------*/
.js-fadeIn {
  opacity: 0;
  visibility: hidden;
}