/*----------------------------------------
Contact
----------------------------------------*/


/*---------- Hero ----------*/
.contact-hero {
  background: url('../images/contact/hero.jpg') center center / cover no-repeat;
}


/*---------- Content ----------*/
.contact {
  &__inner {
    max-width: 96rem;
    padding-top: 9.6rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 4.8rem;
      padding-bottom: 6.4rem;
    }
  }

  &__info {
    background-color: $senary-color;
    padding-top: 4rem;
    padding-right: 5.555555%;
    padding-bottom: 4.8rem;
    padding-left: 5.555555%;
    @include breakpoint-down(xs) {
      padding-top: 3.2rem;
      padding-bottom: 4rem;
    }
  }

  &__info-body {
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint-down(xs) {
      flex-direction: column;
    }
  }

  &__info-title {
    font-size: 1.8rem;
    color: $secondary-color;
    @include breakpoint-down() {
      font-size: 1.6rem;
    }
    @include breakpoint-down(sm) {
      font-size: 1.4rem;
    }
  }

  &__info-link {
    font-size: 3.2rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 1.2;
    color: $secondary-color;
    margin-left: 2.4rem;
    transition: color .4s;
    @include breakpoint-down() {
      font-size: 2.8rem;
    }
    @include breakpoint-down(sm) {
      font-size: 2.4rem;
    }
    @include breakpoint-down(xs) {
      margin-top: .8rem;
      margin-left: 0;
    }

    &:hover {
      color: $tertiary-color;
    }
  }

  &__info-content {
    line-height: 1.4;
    text-align: center;
    color: $secondary-color;
    margin-top: .8rem;

    br {
      display: none;
      @include breakpoint-down(xs) {
        display: block;
      }
    }
  }


  /*---------- Form ----------*/
  &__form {
    border: 1px solid $quaternary-color;
    margin-top: 4.8rem;
    padding-top: 4rem;
    padding-right: 5.555555%;
    padding-bottom: 4.8rem;
    padding-left: 5.555555%;
    @include breakpoint-down(xs) {
      margin-top: 3.2rem;
      padding-top: 3.2rem;
      padding-bottom: 4rem;
    }
  }

  &__form-heading {
    text-align: center;
    color: $secondary-color;
  }

  &__form-body {
    margin-top: 4.8rem;
    @include breakpoint-down() {
      margin-top: 3.2rem;
    }
  }

  .wpcf7 {
    margin-top: 3.2rem;
  }

  .screen-reader-response, .wpcf7-not-valid-tip, .wpcf7-response-output {
    display: none;
  }

  &__form-list {
    gap: 2.4rem;
    @include breakpoint-down(sm) {
      gap: 2rem 4%;
      margin-top: 4.8rem;
    }
  }

  &__form-item {
    width: calc(50% - 1.2rem);
    @include breakpoint-down(sm) {
      width: 48%;
    }

    &:nth-child(n + 3) {
      width: 100%;
    }

    label {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.4;
      color: $secondary-color;
      @include breakpoint-down(sm) {
        font-size: 1.3rem;
      }
    }

    label span.is-required {
      font-weight: 800;
      color: $red-color;
    }

    input:not([type="checkbox"]), textarea {
      background-color: $senary-color;
      width: 100%;
      margin-top: .4rem;
      padding-top: 1.2rem;
      padding-right: 1.6rem;
      padding-bottom: 1.2rem;
      padding-left: 1.6rem;
    }

    textarea {
      height: 16rem;
      resize: vertical;
    }

    input[type="checkbox"] {
      position: relative;
      border: 1px solid $quaternary-color;
      height: 2rem;
      width: 2rem;
      margin-right: .8rem;

      &::after {
        @include absolute($top: .2rem, $left: .5rem);
        transform: rotate(45deg);
        display: block;
        border-right: 3px solid $secondary-color;
        border-bottom: 3px solid $secondary-color;
        content: '';
        height: 1.2rem;
        width: .8rem;
        opacity: 0;
      }

      &:checked::after {
        opacity: 1;
      }

      & + span {
        width: calc(100% - 2.8rem);
        @include breakpoint-down(xs) {
          font-size: 1.1rem;
        }
      }
    }
  }

  &__form-item-link {
    text-decoration: underline;
    color: $secondary-color;
    transition: color .4s;

    &:hover {
      color: $tertiary-color;
    }
  }

  &__form-submit {
    position: relative;
    display: block;
    font-size: 1.4rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 6.4rem;
    text-align: center;
    background-color: $secondary-color;
    height: 6.4rem;
    width: 24rem;
    margin: 3.2rem auto 0;
    transition: background-color .4s;
    overflow: hidden;
    @include breakpoint-down(sm) {
      font-size: 1.3rem;
      margin: 3.2rem auto 0;
    }
    @include breakpoint-down(xs) {
      font-size: 1.2rem;
    }

    &::before {
      @include absolute($top:0, $right: -8rem, $bottom: 0, $left: 0);
      transform: translateX(-100%);
      border-right: 8rem solid transparent;
      border-bottom: 8rem solid $tertiary-color;
      content: '';
      transition: transform .4s;
      z-index: 0;
    }

    &::after {
      @include absolute($top: 50%, $right: 2.4rem);
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 14'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E.cls-1%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M13.17,10l-4,4H12l4-4H13.17z M9.17,0l6,6H0v2h20l-8-8H9.17z'/%3E%3C/svg%3E");
      content: '';
      height: 1.4rem;
      width: 2rem;
      transition: right .4s;
      z-index: 1;
    }

    &:hover {
      &::before {
        transform: translateX(0);
      }

      &::after {
        right: 2rem;
      }
    }

    &.is-disabled {
      background-color: $quinary-color;
      cursor: not-allowed;
      pointer-events: none;
    }

    input {
      position: relative;
      font-weight: 800;
      color: $primary-color-inverse;
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  &__link {
    opacity: 0;
    visibility: hidden;
  }
}