/*----------------------------------------
Header
----------------------------------------*/
.header {
  @include fixed($top: 0, $left: 0);
  width: 100%;
  transition: background-color .4s, border-bottom .4s;
  z-index: 10;

  &__inner {
    margin-right: 2rem;
    margin-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include breakpoint-down() {
      max-width: 90%;
      margin-right: auto;
      margin-left: auto;
    }
    @include breakpoint-down(xs) {
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;
    }
  }

  &__content {
    align-items: center;
  }

  &__logo {
    display: inherit;
    color: $primary-color-inverse;
    width: 20rem;
    pointer-events: auto;
    transition: color .4s;
    @include breakpoint-down(lg) {
      width: 16rem;
    }
    @include breakpoint-down(xs) {
      width: 12.8rem;
    }

    svg {
      fill: currentColor;
    }
  }

  &__nav {
    display: block;
    @include breakpoint-down() {
      display: none;
    }
  }

  &__sp-nav {
    @include fixed($top: 0, $right: 0, $bottom: 0, $left: 0);
    transform: translateX(100%);
    display: none;
    background-color: $secondary-color;
    padding-top: 9.6rem;
    padding-bottom: 6.4rem;
    opacity: 0;
    overflow-y: scroll;
    visibility: hidden;
    transition: transform .4s;
    @include breakpoint-down() {
      display: block;
    }
    @include breakpoint-down(xs) {
      padding-top: 8rem;
    }

    &::before {
      @include absolute($top: -8%, $left: -8%);
      background: url('../images/common/logo-symbol.svg') center center / contain no-repeat;
      content: '';
      height: 100vw;
      width: 100vw;
      opacity: .05;
      @include breakpoint-down(sm) {
        height: 90vw;
        width: 90vw;
      }
    }
  }

  &__nav-list {
    position: relative;
    display: flex;
    align-items: center;
    @include breakpoint-down() {
      flex-direction: column;
    }
  }

  &__nav-item {
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .01em;
    margin-left: 3.2rem;
    @include breakpoint-down(lg) {
      font-size: 1.4rem;
      margin-left: 2.4rem;
    }
    @include breakpoint-down() {
      font-size: 3.2rem;
      width: 100%;
      margin-top: 2.4rem;
      margin-left: 0;
    }
    @include breakpoint-down(xs) {
      font-size: 2rem;
      margin-top: 2.4rem;
    }

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(7) {
      @include breakpoint-down() {
        margin-top: 4.8rem;
      }
      @include breakpoint-down(xs) {
        margin-top: 3.2rem;
      }
    }

    &:last-child {
      @include breakpoint-down() {
        font-size: 1.2rem;
        font-weight: 400;
        margin-top: 4.8rem;
      }
      @include breakpoint-down(xs) {
        margin-top: 3.2rem;
      }
    }
  }

  &__nav-item-link {
    display: block;
    text-align: center;
    color: $primary-color-inverse;
    transition: color .4s;

    &:hover {
      color: $gold-color !important;
    }

    small {
      display: block;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: .2rem;
    }
  }

  &__nav-item-cta {
    position: relative;
    display: block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    background-color: $gold-color;
    color: $primary-color-inverse;
    width: 16rem;
    margin: 0 auto;
    padding: 1rem 0;
    transition: background-color .4s;
    overflow: hidden;
    @include breakpoint-down() {
      width: 24rem;
    }

    &::before {
      @include absolute($top:0, $right: -8rem, $bottom: 0, $left: 0);
      transform: translateX(-100%);
      border-right: 8rem solid transparent;
      border-bottom: 8rem solid $tertiary-color;
      content: '';
      transition: transform .4s;
      z-index: 0;
    }

    &:hover {
      &::before {
        transform: translateX(0);
      }
    }

    svg {
      position: relative;
      display: block;
      fill: currentColor;
      width: 2rem;
      margin: 0 auto .4rem;
      z-index: 1;
    }

    span {
      position: relative;
      z-index: 1;
    }
  }

  &__button {
    position: relative;
    display: none;
    cursor: pointer;
    height: 2.2rem;
    width: 2.4rem;
    @include breakpoint-down() {
      display: block;
    }

    &::before {
      @include absolute($top: 50%, $left: -6.4rem);
      transform: translateY(-50%);
      display: none;
      font-size: 1.6rem;
      font-weight: 700;
      color: $primary-color-inverse;
      content: 'Close';
    }

    span {
      @include absolute($right: 0);
      background: $primary-color-inverse;
      height: .3rem;
      transition: all .4s;
    }

    span:nth-of-type(1) {
      top: 0;
      width: 2.4rem;
    }

    span:nth-of-type(2) {
      top: .9rem;
      width: 1.8rem;
    }

    span:nth-of-type(3) {
      top: 1.8rem;
      width: 1.2rem;
    }
  }
}


/*---------- Scrolled ----------*/
.header.is-scrolled {
  background-color: $primary-color-inverse;
  border-bottom: 1px solid $quinary-color;

  .header__logo {
    color: $secondary-color;
  }

  .header__nav-item-link {
    color: $secondary-color;
    @include breakpoint-down() {
      color: $primary-color-inverse;
    }
  }

  .header__button {
    span {
      background: $secondary-color;
    }
  }
}


/*---------- Opened ----------*/
body.is-opened .header {
  .header__sp-nav {
    @include breakpoint-down() {
      transform: translateX(0);
    }
  }

  .header__button {
    &::before {
      display: block;
    }

    span {
      background: $primary-color-inverse;
    }

    span:nth-of-type(1) {
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(-135deg);
      width: 2.4rem;
    }

    span:nth-of-type(2) {
      opacity: 0;
    }

    span:nth-of-type(3) {
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(135deg);
      width: 2.4rem;
    }
  }
}