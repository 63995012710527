/*----------------------------------------
Footer
----------------------------------------*/
.footer {
  position: relative;
  font-weight: 400;
  background-color: $secondary-color;
  color: $primary-color-inverse;
  overflow: hidden;

  &::before {
    @include absolute($top: 50%, $right: -5%);
    transform: translateY(-50%);
    background: url('../images/common/logo-symbol.svg') center center / contain no-repeat;
    content: '';
    height: 55vw;
    width: 55vw;
    opacity: .05;
    @include breakpoint-down(sm) {
      right: -15%;
      height: 75vw;
      width: 75vw;
    }
    @include breakpoint-down(xs) {
      top: -10%;
      right: -20%;
      transform: inherit;
      height: 90vw;
      width: 90vw;
    }
  }

  &__inner {
    position: relative;
    padding-top: 8rem;
    padding-bottom: 4rem;
    @include breakpoint-down(xs) {
      padding-top: 4.8rem;
      padding-bottom: 2.4rem;
    }
  }

  &__block {
    &:last-child {
      align-items: baseline;
      margin-top: 12.8rem;
      @include breakpoint-down(sm) {
        margin-top: 6.4rem;
      }
    }
  }

  &__left {
    width: 36%;
    @include breakpoint-down(lg) {
      width: 100%;
    }

    p {
      font-size: 1.2rem;
      margin-top: 3.2rem;
    }
  }

  &__left-logo {
    display: block;
    width: 20rem;
    @include breakpoint-down(sm) {
      width: 16rem;
    }

    svg {
      fill: $primary-color-inverse;
    }
  }

  &__left-tel {
    font-size: 1.2rem;
    margin-top: 1.2rem;

    a {
      color: $primary-color-inverse;
    }
  }

  &__right {
    width: 64%;
    @include breakpoint-down(lg) {
      width: 100%;
      margin-top: 6.4rem;
    }
  }

  &__right-list {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
    width: 30%;
    @include breakpoint-down(sm) {
      display: none;
    }
  }

  &__right-item {
    font-size: 1.4rem;
    @include breakpoint-down(sm) {
      font-size: 1.3rem;
    }

    & > a {
      color: $primary-color-inverse;
      transition: opacity .4s;

      &:hover {
        opacity: .6;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;
      margin-top: 1.2rem;
    }

    li {
      position: relative;
      padding-left: 2.4rem;

      &::before {
        @include absolute($top: 50%, $left: 0);
        transform: translateY(-50%);
        background-color: currentColor;
        content: '';
        height: .2rem;
        width: 1.2rem;
        opacity: .6;
      }
    }

    li > a {
      color: $primary-color-inverse;
      opacity: .6;
      transition: opacity .4s;

      &:hover {
        opacity: .3;
      }
    }
  }

  &__right-teams {
    width: 40%;
    @include breakpoint-down(sm) {
      width: 100%;
    }

    h3 {
      font-size: 1.6rem;
      font-weight: 700;
      letter-spacing: .01em;
    }

    p {
      font-size: 1rem;
      margin-top: 1.6rem;
    }
  }

  &__right-logos {
    align-items: center;
    justify-content: flex-start;
    column-gap: 2.4rem;
    margin-top: 2.4rem;
  }

  &__right-logo {
    display: block;

    &:first-child {
      width: 3.2rem;
    }

    &:last-child {
      width: 8.8rem;
    }
  }

  &__copyright {
    font-size: 1.2rem;
    font-weight: 700;
    @include breakpoint-down(sm) {
      text-align: center;
      margin-top: 2.4rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.1rem;
    }
  }

  &__logo {
    display: block;
    width: 16rem;
    @include breakpoint-down(xs) {
      width: 12.8rem;
    }
  }
}