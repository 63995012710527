/*----------------------------------------
Features
----------------------------------------*/


/*---------- Hero ----------*/
.features-hero {
  background: url('../images/features/hero.jpg') center center / cover no-repeat;
}


/*---------- Intro ----------*/
.features-intro {
  margin-top: 12.8rem;
  @include breakpoint-down() {
    margin-top: 9.6rem;
  }
  @include breakpoint-down(sm) {
    margin-top: 8rem;
  }
  @include breakpoint-down(xs) {
    margin-top: 6.4rem;
  }

  &__heading {
    text-align: center;

    br.is-sp {
      display: none;
      @include breakpoint-down(sm) {
        display: block;
      }
    }
  }

  &__list {
    margin-top: 6.4rem;
    @include breakpoint-down(sm) {
      row-gap: 1.6rem;
      margin-top: 4.8rem;
    }
  }

  &__item {
    max-width: 48rem;
    width: 32%;
    @include breakpoint-down(sm) {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__item-link {
    position: relative;
    display: block;
    border: .2rem solid $quinary-color;
    height: 100%;
    padding: 4.8rem 2rem 6rem;
    transition: background-color .4s;
    @include breakpoint-down(sm) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 2.4rem;
      padding: 2rem 2rem 4.4rem;
    }

    &::before {
      @include absolute($bottom: 2rem, $left: 50%);
      transform: translateX(-50%) rotate(90deg);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 14'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E.cls-1%7Bfill:%2335415f;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M13.17,10l-4,4H12l4-4H13.17z M9.17,0l6,6H0v2h20l-8-8H9.17z'/%3E%3C/svg%3E");
      content: '';
      height: 1.4rem;
      width: 2rem;
      @include breakpoint-down(sm) {
        bottom: 1.6rem;
      }
    }

    &:hover {
      background-color: $senary-color;
    }

    img {
      display: block;
      width: 16rem;
      margin: 0 auto;
      @include breakpoint-down() {
        width: 11.2rem;
      }
      @include breakpoint-down(sm) {
        width: 6.4rem;
      }
    }

    h3 {
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
      color: $tertiary-color;
      margin-top: 3.2rem;
      @include breakpoint-down(sm) {
        text-align: left;
        width: calc(100% - 8.8rem);
        margin-top: 0;
      }
    }

    p {
      border-top: .2rem solid $quinary-color;
      margin-top: 2.4rem;
      padding-top: 2.4rem;
      @include breakpoint-down(sm) {
        width: 100%;
        margin-top: 1.6rem;
        padding-top: 1.6rem;
      }
    }
  }
}


/*---------- Reason ----------*/
.features-reason {
  overflow: hidden;

  &#reason1 {
    margin-top: 12.8rem;
    @include breakpoint-down() {
      margin-top: 9.6rem;
    }
    @include breakpoint-down(sm) {
      margin-top: 8rem;
    }
    @include breakpoint-down(xs) {
      margin-top: 6.4rem;
    }
  }

  &:nth-child(odd) {
    background-color: $senary-color;
  }

  &__inner {
    position: relative;
    max-width: 96rem;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding: 12.8rem 0 6.4rem;
    @include breakpoint-down(sm) {
      padding: 9.6rem 0 6.4rem;
    }
    @include breakpoint-down(xs) {
      padding: 6.4rem 0 3.2rem;
    }
  }

  &__num {
    position: relative;
    font-size: 1.2rem;
    font-weight: 800;
    background-color: $gold-color;
    color: $primary-color-inverse;
    width: fit-content;
    padding: .4rem .8rem;

    &::before {
      @include absolute($top: 50%, $left: -100vw);
      transform: translateY(-50%);
      background-color: $gold-color;
      content: '';
      height: .2rem;
      width: 100vw;
    }
  }

  &__heading {
    align-items: flex-start;
    margin-top: -2.4rem;
    @include breakpoint-down(sm) {
      flex-direction: column-reverse;
    }

    img {
      display: block;
      width: 32rem;
      @include breakpoint-down() {
        width: 24rem;
      }
      @include breakpoint-down(sm) {
        width: 16rem;
        margin: 0 auto;
      }
    }
  }

  &__heading-body {
    width: calc(100% - 32rem);
    padding-top: 8rem;
    @include breakpoint-down() {
      width: calc(100% - 26.4rem);
    }
    @include breakpoint-down(sm) {
      width: 100%;
      padding-top: 2.4rem;
    }

    h2 {
      color: $secondary-color;
      @include breakpoint-down(sm) {
       text-align: center;
      }
    }

    p {
      font-size: 1.6rem;
      max-width: 56rem;
      margin-top: 2.4rem;
    }
  }

  &__list {
    margin-top: 4.8rem;
  }

  &__item {
    align-items: flex-start;
    column-gap: 4.8rem;
    border-top: .2rem solid $quinary-color;
    padding: 4.8rem 0 6.4rem;
    @include breakpoint-down() {
      column-gap: 2.4rem;
    }
    @include breakpoint-down(sm) {
      padding: 3.2rem 0;
    }

    img {
      display: block;
      width: calc(50% - 2.4rem);
      @include breakpoint-down() {
        width: calc(50% - 1.2rem);
      }
      @include breakpoint-down(sm) {
        max-width: 32rem;
        width: 80%;
        margin: 0 auto;
      }
    }

    img.is-large {
      @include breakpoint-down(sm) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &__item.is-reverse {
    flex-direction: column-reverse;

    img {
      max-width: 100%;
      width: 100%;
      margin-top: 4.8rem;
      @include breakpoint-down(sm) {
        margin-top: 2.4rem;
      }
    }

    .features-reason__item-body {
      max-width: 64rem;
      width: 100%;
      margin: 0 auto;
    }

    .features-reason__item-body h3 {
      text-align: center;
    }
  }

  &__item-body {
    width: calc(50% - 2.4rem);
    @include breakpoint-down() {
      width: calc(50% - 1.2rem);
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-top: 2.4rem;
    }

    h3 {
      color: $tertiary-color;
      @include breakpoint-down(sm) {
        text-align: center;
      }
    }

    br.is-sp {
      display: none;
      @include breakpoint-down(sm) {
        display: block;
      }
    }

    p {
      max-width: 64rem;
      margin-top: 3.2rem;
      margin-right: auto;
      margin-left: auto;
      @include breakpoint-down(sm) {
        margin-top: 2.4rem;
      }
    }

    p + p {
      margin-top: 1.6rem;
    }
  }
}