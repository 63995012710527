/*----------------------------------------
Hero
----------------------------------------*/
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48rem;
  @include breakpoint-down(sm) {
    height: 32rem;
  }
  @include breakpoint-down(xs) {
    height: 24rem;
  }

  &__inner {
    padding-top: 2.4rem;
  }

  &__title--primary {
    font-size: 3.2rem;
    font-weight: 700;
    letter-spacing: .02em;
    line-height: 1.2;
    text-align: center;
    color: $primary-color-inverse;
    @include breakpoint-down() {
      font-size: 2.8rem;
    }
    @include breakpoint-down(sm) {
      font-size: 2.4rem;
    }

    small {
      position: relative;
      display: block;
      font-size: 1.4rem;
      width: fit-content;
      margin: 1.6rem auto 0;
      @include breakpoint-down(sm) {
        font-size: 1.3rem;
      }

      &::before, &::after {
        @include absolute($top: 50%);
        transform: translateY(-50%);
        background-color: currentColor;
        content: '';
        height: .2rem;
        width: 1.6rem;
      }

      &::before {
        left: -2.8rem;
      }

      &::after {
        right: -2.8rem;
      }
    }
  }

  &__title--secondary {
    font-size: 3.2rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 1.2;
    color: $primary-color-inverse;
    padding-top: 16rem;
    padding-bottom: 8rem;
    @include breakpoint-down() {
      font-size: 2.8rem;
    }
    @include breakpoint-down(sm) {
      font-size: 2.4rem;
    }
    @include breakpoint-down(xs) {
      font-size: 2rem;
      line-height: 1.4;
      padding-top: 9.6rem;
      padding-bottom: 6.4rem;
    }
  }
}