/*----------------------------------------
Link
----------------------------------------*/
.link {
  position: relative;
  display: block;
  font-size: 1.4rem;
  font-weight: 800;
  letter-spacing: .02em;
  line-height: 6.4rem;
  text-align: center;
  background-color: $secondary-color;
  color: $primary-color-inverse;
  height: 6.4rem;
  width: 24rem;
  overflow: hidden;
  @include breakpoint-down(sm) {
    font-size: 1.3rem;
  }
  @include breakpoint-down(xs) {
    font-size: 1.2rem;
  }

  &::before {
    @include absolute($top:0, $right: -8rem, $bottom: 0, $left: 0);
    transform: translateX(-100%);
    border-right: 8rem solid transparent;
    border-bottom: 8rem solid $tertiary-color;
    content: '';
    transition: transform .4s;
    z-index: 0;
  }

  &::after {
    @include absolute($top: 50%, $right: 2.4rem);
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 14'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E.cls-1%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M13.17,10l-4,4H12l4-4H13.17z M9.17,0l6,6H0v2h20l-8-8H9.17z'/%3E%3C/svg%3E");
    content: '';
    height: 1.4rem;
    width: 2rem;
    transition: right .4s;
    z-index: 1;
  }

  &:hover {
    &::before {
      transform: translateX(0);
    }

    &::after {
      right: 2rem;
    }
  }

  &__text {
    position: relative;
    z-index: 1;
  }
}