/*----------------------------------------
News Pagination
----------------------------------------*/
.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin-top: 6.4rem;
  @include breakpoint-down(xs) {
    margin-top: 3.2rem;
  }

  &__prev, &__next {
    position: relative;
    display: block;
    font-size: 1.6rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 1.4;
    background-color: $secondary-color;
    color: $primary-color-inverse;
    width: fit-content;
    padding-top: 1.2rem;
    padding-right: 2rem;
    padding-bottom: 1.2rem;
    padding-left: 2rem;
    overflow: hidden;
    transition: color .4s;
    @include breakpoint-down(md) {
      font-size: 1.4rem;
    }
    @include breakpoint-down(sm) {
      font-size: 1.3rem;
    }
    @include breakpoint-down(xs) {
      font-size: 1.2rem;
      padding-right: 1.6rem;
      padding-left: 1.6rem;
    }

    &:hover {
      color: $primary-color-inverse;
    }

    &.is-disabled {
      background-color: $quinary-color;
      color: $quaternary-color;
      pointer-events: none;
    }
  }

  &__prev {
    margin-right: 2.8rem;
    @include breakpoint-down(xs) {
      margin-right: auto;
    }

    &::before {
      position: relative;
      display: inline-block;
      content: '❮';
      margin-right: .8rem;
      transition: transform .4s;
      z-index: 1;
      @include breakpoint-down(xs) {
        margin-right: 0;
      }
    }

    &::after {
      @include absolute($top:0, $right: -8rem, $bottom: 0, $left: 0);
      transform: translateX(-100%);
      border-right: 8rem solid transparent;
      border-bottom: 8rem solid $tertiary-color;
      content: '';
      transition: transform .4s;
      z-index: 0;
    }

    &:hover {
      &::before {
        transform: translateX(-.3rem);
      }

      &::after {
        transform: translateX(0);
      }
    }
  }

  &__next {
    margin-left: 2.8rem;
    @include breakpoint-down(xs) {
      margin-left: auto;
    }

    &::before {
      @include absolute($top:0, $right: -8rem, $bottom: 0, $left: 0);
      transform: translateX(-100%);
      border-right: 8rem solid transparent;
      border-bottom: 8rem solid $tertiary-color;
      content: '';
      transition: transform .4s;
      z-index: 0;
    }

    &::after {
      position: relative;
      display: inline-block;
      content: '❯';
      margin-left: .8rem;
      transition: transform .4s;
      z-index: 1;
      @include breakpoint-down(xs) {
        margin-left: 0;
      }
    }

    &:hover {
      &::before {
        transform: translateX(0);
      }

      &::after {
        transform: translateX(.3rem);
      }
    }
  }

  &__prev-text, &__next-text {
    position: relative;
    z-index: 1;
    @include breakpoint-down(xs) {
      display: none;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: .02em;
    line-height: 1.4;
    color: $quaternary-color;
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    transition: color .4s;
    @include breakpoint-down() {
      font-size: 1.6rem;
    }
    @include breakpoint-down(sm) {
      font-size: 1.4rem;
    }

    &:hover {
      color: $accent-color;
    }

    &.is-disabled {
      color: $secondary-color;
      pointer-events: none;
    }
  }
}