/*----------------------------------------
Privacy
----------------------------------------*/


/*---------- Hero ----------*/
.privacy-hero {
  background: url('../images/privacy/hero.jpg') center center / cover no-repeat;
}


/*---------- Content ----------*/
.privacy {
  &__inner {
    padding-top: 9.6rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 4.8rem;
      padding-bottom: 6.4rem;
    }
  }

  &__clause {
    width: 75%;
    @include breakpoint-down(lg) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }

    &:not(:first-of-type) {
      margin-top: 4rem;
    }
  }

  &__clause-heading {
    font-size: 1.6rem;
    line-height: 1.4;
    color: $secondary-color;
    @include breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  &__clause-content {
    margin-top: 2.4rem;
  }

  &__clause-tel {
    &:hover {
      text-decoration: underline;
    }
  }

  &__clause-list {
    counter-reset: count 0;
    margin-left: 2.4rem;
    @include breakpoint-down(xs) {
      margin-left: 0;
    }
  }

  &__clause-item {
    position: relative;
    font-size: 1.4rem;
    line-height: 2;
    margin-top: 1.6rem;
    padding-left: 3.2rem;
    @include breakpoint-down(sm) {
      font-size: 1.3rem;
    }

    &::before {
      @include absolute($top: 0, $left: 0);
      counter-increment: count 1;
      content: '('counter(count)')';
    }
  }
}