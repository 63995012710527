/*----------------------------------------
Thanks
----------------------------------------*/


/*---------- Hero ----------*/
.thanks-hero {
  background-color: $secondary-color;
}


/*---------- Content ----------*/
.thanks {
  &__inner {
    padding-top: 9.6rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 4.8rem;
      padding-bottom: 6.4rem;
    }
  }

  &__block {
    width: 75%;
    @include breakpoint-down(lg) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }

    p {
      margin-top: 2.4rem;
    }
  }

  &__heading {
    color: $gold-color;
  }

  &__link {
    margin-top: 4.8rem;
    @include breakpoint-down(sm) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}