/*----------------------------------------
CTA
----------------------------------------*/
.cta {
  @include fixed($right: -2rem, $bottom: 4rem);
  align-items: center;
  background-color: currentColor;
  border: .2rem solid currentColor;
  color: $tertiary-color;
  height: 6.4rem;
  width: 32rem;
  opacity: 0;
  pointer-events: none;
  transition: right .4s, bottom .4s, color .4s, opacity .4s;
  z-index: 9;
  @include breakpoint-down(xs) {
    right: 0;
    bottom: -2rem;
    height: 6rem;
    width: 100%;
  }

  &::before {
    @include absolute($top: 50%, $right: 2.4rem);
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 14'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E.cls-1%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M13.17,10l-4,4H12l4-4H13.17z M9.17,0l6,6H0v2h20l-8-8H9.17z'/%3E%3C/svg%3E");
    height: 1.4rem;
    width: 2rem;
    transition: right .4s;
    z-index: 1;
    @include breakpoint-down(xs) {
      content: '';
    }
  }

  &:hover {
    color: $quaternary-color;

    &::before {
      right: 2rem;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color-inverse;
    height: 6rem;
    width: 6rem;
    @include breakpoint-down(xs) {
      display: none;
    }

    svg {
      fill: $secondary-color;
      width: 3.6rem;
    }

  }

  &__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    color: $primary-color-inverse;
    height: 100%;
    width: 25.6rem;
    @include breakpoint-down(xs) {
      width: 100%;
    }

    span {
      position: relative;
      display: block;
      font-size: 1.2rem;
      font-weight: 500;
      width: fit-content;
      padding-bottom: .1rem;

      &::before {
        @include absolute($bottom: 0, $left: 0);
        background-color: $primary-color-inverse;
        content: '';
        height: .1rem;
        width: 100%;
      }
    }
  }
}


/*---------- Scrolled ----------*/
.cta.is-scrolled {
  right: 0;
  opacity: 1;
  pointer-events: inherit;
  @include breakpoint-down(xs) {
    bottom: 0;
  }
}