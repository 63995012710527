/*----------------------------------------
Footer Contact
----------------------------------------*/
.footer-contact {
  position: relative;
  background-color: $tertiary-color;

  &__body {
    width: 48%;
    padding-top: 6.4rem;
    padding-right: 8%;
    padding-left: 8%;
    padding-bottom: 12.8rem;
    @include breakpoint-down(sm) {
      width: 55vw;
      padding-left: 0;
    }
    @include breakpoint-down(xs) {
      width: 100%;
      padding-top: 4.8rem;
      padding-right: 0;
      padding-bottom: 6.4rem;
    }
  }

  &__heading {
    color: $primary-color-inverse;
  }

  &__summary {
    color: $primary-color-inverse;
    margin-top: 3.2rem;
    @include breakpoint-down(xs) {
      margin-top: 2.4rem;
    }
  }

  &__panel {
    @include absolute($top: 0, $right: 0);
    height: 100%;
    width: 40%;
    perspective: 1200px;
    overflow: hidden;
    @include breakpoint-down(xs) {
      position: relative;
      width: 100%;
    }

    &::before {
      @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
      background-color: $primary-color;
      content: '';
    }
  }

  &__panel-link {
    position: relative;
    display: flex;
    align-items: flex-end;
    background-color: $accent-color;
    height: 100%;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    transition: transform .4s;
    @include breakpoint-down(xs) {
      justify-content: right;
      height: auto;
      width: 100%;
      margin-left: auto;
      padding-top: 2.4rem;
      padding-right: 5vw;
      padding-bottom: 2.4rem;
    }

    &:hover {
      transform: rotateY(-11deg) rotateX(8deg) scale(0.995);
    }
  }

  &__panel-link-text {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    color: $primary-color-inverse;
    @include breakpoint-down(sm) {
      font-size: 1.8rem;
      line-height: 1.4;
    }
    @include breakpoint-down(xs) {
      font-size: 1.6rem;
    }

    &::after {
      content: '❯';
      margin-left: 1.2rem;
    }
  }
}