/*----------------------------------------
Error
----------------------------------------*/


/*---------- Hero ----------*/
.error-hero {
  background-color: $secondary-color;
}


/*---------- Content ----------*/
.error {
  &__inner {
    padding-top: 9.6rem;
    padding-bottom: 16rem;
    @include breakpoint-down() {
      padding-top: 8rem;
      padding-bottom: 9.6rem;
    }
    @include breakpoint-down(xs) {
      padding-top: 4.8rem;
      padding-bottom: 6.4rem;
    }
  }

  &__block {
    width: 75%;
    @include breakpoint-down(lg) {
      width: 85%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__heading {
    font-size: 1.6rem;
    line-height: 1.4;
    color: $secondary-color;
    @include breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  &__content {
    margin-top: 2.4rem;
  }
}